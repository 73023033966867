var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-user-page"},[_c('v-app',[_c('v-container',{staticClass:"login-user-container",style:(_vm.institution
          ? {
              'background-image': 'url(' + _vm.institution.image + ')',
            }
          : {}),attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"5","align-self":"center"}},[_c('v-scroll-y-transition',[(_vm.institution)?_c('v-card',{staticClass:"login-user-card text-center px-2 py-4 mt-4",attrs:{"transition":"fade-transition"}},[(_vm.institution)?_c('div',{staticClass:"rounded-image mb-4",style:({
                  'background-image':
                    'url(' + _vm.institution.logo || '' + ') !important',
                  'background-size': 'cover',
                  'background-position': 'center',
                  'background-size': 'cover',
                })}):_vm._e(),_c('h3',[_vm._v(_vm._s(_vm.institution.name))]),_c('p',[_vm._v(_vm._s(_vm.institution.location))]),_c('v-form',{staticClass:"px-2",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[(_vm.formErrors.genError)?_c('p',{staticClass:"form-error",attrs:{"data-cy":"login-user-form-errors"}},[_vm._v(" "+_vm._s(_vm.formErrors.genError)+" ")]):_vm._e(),_c('v-text-field',{staticClass:"mb-4 username-field",attrs:{"solo":"","clearable":"","label":"Username","rounded":"","error-messages":_vm.formErrors.usernameError,"hide-details":"auto"},on:{"input":_vm.resetErrors},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{staticClass:"mb-4",attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"solo":"","label":"Password","rounded":"","error-messages":_vm.formErrors.passwordError,"hide-details":"auto"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword},"input":_vm.resetErrors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-btn',{attrs:{"disabled":_vm.isLoading,"large":"","color":"primary","block":"","type":"submit"}},[_vm._v("Log In")]),_c('forgot-password-sheet')],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }